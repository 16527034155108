













import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import { RequestData } from "@/store/types/RequestStore";
import { namespace } from "vuex-class";
import { ShippingContract } from "@/models";

const http = namespace("http");
@Component({ components: { VSelect } })
export default class CarrrierSelect extends Vue {

  @Prop()
  selected!: string | null;

  @Prop()
  queryKey?: string;

  @Prop()
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  @Prop()
  filter: any;

  options: VSelectOption[] = [];

  private value: string | null = this.selected || null;

  mounted() {
    this.loadItems();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100
      }
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }


  get tag() {
    return `get_shipping_method_tag`;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
     this.$emit("inputRaw", ShippingContract.carriers.find(o => o.id === id));

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  loadItems() {
    this.options = [
      ...ShippingContract.carriers.map(o => {
        return { id: o.id, text: o.name };
      })
    ];

    if (this.actsAsFilter) {
      this.options.unshift({ id: "all", text: "Tutti" });
    }

    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }
}
